import { getRequest ,deleteRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const getuserManagements=(params)=>{
    return getRequest(API.getUser,params);
}

export const deleteUser=(params)=>{
    return deleteRequest(API.getUser+'/'+params);
}

export const userDetail=(params)=>{
    return getRequest(API.getUser+'/'+params);
}

export const getAllrole=(params)=>{
    return getRequest(API.getAllrole,params);
}

export const userEdit=(params)=>{
    return postRequest(API.userEdit,params);
}
